import { Component, OnInit } from '@angular/core';
import { Global } from '../global';

declare var $;

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.scss']
})
export class AperturaComponent implements OnInit {

  paso = 1;
  apertura = false;
  formulario;
  poblaciones = [];
  cargando = false;
  subiendo_ficha = false;
  confirmEmail = false;
  centros = [];
  causas = [];
  tipologias = [];
  pide = true;
  n_files = {};
  grm_id;
  allDocumentos = [];
  files = [];
  documentos = [];
  subiendo = {};
  error_abonado = null;
  centroId;
  cp_centro;

  sin_alias;





  constructor(public global: Global) { }

  async ngOnInit() {

    await this.getCentros();
    this.getDocuments();

  }

  async getDocuments() {
    this.cargando = true;
    let result = await this.global.apiCall("hiperusera/getDocuments", "POST", false, {});
    this.cargando = false;
    if (result.status == false) {
      if (result.message) alert(result.message);
      return;
    }

    this.allDocumentos = result.data;

  }

  async getCentros() {
    this.cargando = true;
    let result = await this.global.apiCall("hiperusera/getCentros", "GET", false, {});
    this.cargando = false;

    if (!result.status) {
      alert(result.message);
      return;
    }
    // console.log('getCentros, ',result.data);
    this.centros = result.data;
  }

  async getTipologiaSiniestros() {
    this.cargando = true;
    let result = await this.global.apiCall(
      "hiperusera/tipologiaSiniestros",
      "POST",
      false,
      {
        grm_id: this.grm_id,
        tipoSiniestro: this.formulario.tipoSiniestro.value
      });
    this.cargando = false;
    if (result.status == false) {
      if (result.message) alert(result.message);
      return;
    }

    this.tipologias = result.data;
    this.tipologias.push({
      tps_id: -1,
      tps_codigo: -1,
      tps_descripcion: "No estoy seguro"
    });

  }

  async getCausas() {

    this.causas = [];

    if (this.formulario.tipologiaSiniestro.value == null) return;
    let tipologia = this.tipologias.find(t => t.tps_id == this.formulario.tipologiaSiniestro.value);
    this.cargando = true;
    let result = await this.global.apiCall(
      "hiperusera/causaSiniestros",
      "POST",
      false, {
        tps_codigo: tipologia.tps_codigo,
        tipoSiniestro: this.formulario.tipoSiniestro.value
      });
    this.cargando = false;
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      }
      else {
        this.causas.push({
          csn_descripcion: "No estoy seguro",
          csn_id: -1
        });
        this.formulario.causaSiniestro.value = this.causas[0].csn_id;
      }
      return;
    }

    this.causas = result.data;
    if(this.causas.length == 1){
      this.formulario.causaSiniestro.value = this.causas[0].csn_id;
    } else {
      this.causas.push({
        csn_descripcion: "No estoy seguro",
        csn_id: -1
      });
    }
  }

  async getGRM() {
    let centro = this.centros.find(c => c.riesgo == this.formulario.centro.value);
    let poliza_id = '';
    if (this.formulario.tipoSiniestro.value != 'Daños') {
      poliza_id = centro.pol_id_rc;
    } else {
      poliza_id = centro.pol_id_daños;
    }
    this.formulario.rie_id.value = centro.rie_id;

    this.cargando = true;

    let result = await this.global.apiCall("hiperusera/getGRM", "POST", false, { pol_id: poliza_id, centro:centro });
    // console.log('result',result);
    this.cargando = false;
    if (result.status == false) {
      if (result.message) alert(result.message);
      return;
    }
    let data = result.data;
    if (data.grm_id) this.grm_id = data.grm_id;
    else this.grm_id = data.GRM_Id;

    this.formulario.pol_id.value = poliza_id;
    this.formulario.poliza.value = data.poliza;
    this.formulario.nif.value = data.nif;
    // this.formulario.rie_id.value = data.rie_id;
    const contactoDatos = await this.global.apiCall('siniestros/getContactoDatos', 'POST', false, {pol_id: poliza_id});
    const contactDefault = contactoDatos.data;

    if (this.formulario.tipoSiniestro.value === 'Daños') {
      this.formulario.nombre.value = contactDefault != null ?  contactDefault.ctc_nombre : '' ;
      this.formulario.telefono.value =  contactDefault != null  ? contactDefault.ctc_telefono : '';
      this.formulario.email.value =  contactDefault != null ? contactDefault.ctc_email :  '' ;
    } else{
      this.formulario.nombre_contacto_rc.value = contactDefault != null ?  contactDefault.ctc_nombre : '' ;
      this.formulario.telefono_contacto_rc.value = contactDefault != null  ? contactDefault.ctc_telefono : '';
      this.formulario.email_contacto_rc.value = contactDefault != null ? contactDefault.ctc_email :  '' ;
    }



    const direccionexplode= this.explodeDireccion(this.formulario.centro.value);

    if (direccionexplode.status == true) {
      this.formulario.cp.value = direccionexplode.cp;
      this.formulario.dirPerjudicado.value = direccionexplode.direccion;
      let c = await this.checkCP(this.formulario.cp.value);

      if (this.formulario.poblacion.value == '' && this.poblaciones.length != 0) {
        this.formulario.poblacion.value = this.poblaciones[0];
      }
    }


  }




  init_formulario() {

    this.formulario = {
      pol_id: {
        value: '',
        paso: 1
      },
      centro: {
        value: '',
        error: null,
        paso: 1
      },
      tipoSiniestro: {
        value: 'Daños',
        error: null,
        paso: 1
      },
      poliza: {
        value: '',
        paso: 1
      },
      rie_id: {
        value: '',
        paso: 1
      },
      nif: {
        value: '',
        paso: 1
      },
      tipologiaSiniestro: {
        value: null,
        error: null,
        paso: 2
      },
      causaSiniestro: {
        value: null,
        error: null,
        paso: 2
      },
      fecha: {
        value: '',
        error: null,
        paso: 2
      },
      dirPerjudicado: {
        value: '',
        error: null,
        paso: 2
      },
      cp: {
        value: '',
        error: null,
        paso: 2
      },
      poblacion: {
        value: '',
        error: null,
        paso: 2
      },
      circunstancias: {
        value: '',
        error: null,
        paso: 2
      },
      danos: {
        value: '',
        error: null,
        paso: 2
      },
      tipo_persona: {
        value: '1',
        error: null,
        paso: 3,
        danos: false
      },
      nombre: {
        value: '',
        error: null,
        paso: 3,
        tipo_persona: 1,
        danos: true
      },
      apellido1: {
        value: '',
        error: null,
        paso: 3,
        tipo_persona: 1,
        danos: true
      },
      apellido2: {
        value: '',
        error: null,
        paso: 3,
        tipo_persona: 1,
        danos: false
      },
      razonSocial: {
        value: '',
        error: null,
        paso: 3,
        tipo_persona: 2,
        danos: false
      },
      telefono: {
        value: '',
        error: null,
        paso: 3,
        danos: true
      },
      dni_nif: {
        value: '',
        error: null,
        paso: 3,
        danos: true,
      },
      email: {
        value: '',
        error: null,
        paso: 3,
        danos: true
      },
      contacto: {
        value: '',
        error: null,
        paso: 3,
        tipo_persona: 2,
        danos: false
      },
      sin_id: {
        value: null,
        error: null,
        paso: 'sin_id'
      },

      nombre_contacto_rc:{
        value: '',
        error: null,
        paso: 4,
      },
      apellido1_contacto_rc:{
        value: '',
        error: null,
        paso: 4,
      },
      apellido2_contacto_rc:{
        value: '',
        error: null,
        paso: 4,
      },
      telefono_contacto_rc: {
        value: '',
        error: null,
        paso: 4,

      },
      dni_nif_contacto_rc: {
        value: '',
        error: null,
        paso: 4,

      },
      email_contacto_rc: {
        value: '',
        error: null,
        paso: 4,

      },
    };

    this.n_files = {};
    this.paso = 1;
    this.poblaciones = [];
    this.files = [];
    this.documentos = [];

    this.iniTable(null);
    setTimeout(() => {
      $("#fecha").datepicker({
        regional:'es',
        language: 'es',
        dateFormat: 'dd/mm/yy',
        maxDate: 0,
        firstDay: 1,
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      }).change(() => {
        this.formulario.fecha.error = null;
      });
      $('#select2Documentos').val(this.documentos);
      $('#select2Documentos').select2({
        dropdownParent: $("#panel"),
        placeholder: 'Selecciona los documentos a añadir',
      }).on('select2:select', (e) => {
        let doc = this.allDocumentos.find(t => t.doc_id == e.params.data.id);
        this.formulario[doc.doc_descripcion] = {
          value: [],
          error: null,
          id: doc.doc_id,
          paso: 'documentos'
        }
        this.subiendo[doc.doc_id] = false;
        this.n_files[doc.doc_id] = 1;
        this.documentos.push(doc);
        if (this.documentos.length == 1) {
          $('#docsTable').DataTable().destroy();
          this.iniTable(0);
        }
      }).on('select2:unselect', (e) => {
        let doc = this.documentos.find(t => t.doc_id == e.params.data.id);
        this.documentos.splice(this.documentos.indexOf(doc), 1);
        if (this.documentos.length == 0) {
          $('#docsTable').DataTable().destroy();
          this.iniTable(0);
        }
      });
    }, 100);

  }

  iniTable(id) {
    setTimeout(() => {
      if (id == 0 || id == null) {
        $('#docsTable').DataTable({
          lengthMenu: [150],
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
          },
        });
      }
      if (id == 1 || id == null) {
        $('#perjudicadosTable').DataTable({
          lengthMenu: [150],
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
          },
        });
      }
    }, 100);
  }

  setTipoSiniestroByCentro() {
    this.formulario.centro.error = null;
    let centro = this.centros.find(c => c.riesgo == this.formulario.centro.value);
    if (centro.daños == '1' && centro.RC == '0') {
      this.formulario.tipoSiniestro.value = 'Daños';
      $('#radioDanos').prop('disabled', true);
      $('#radioRC').prop('disabled', true);
    } else if (centro.daños == '0' && centro.RC == '1') {
      this.formulario.tipoSiniestro.value = 'Responsabilidad Civil';
      $('#radioDanos').prop('disabled', true);
      $('#radioRC').prop('disabled', true);
    } else {
      $('#radioDanos').prop('disabled', false);
      $('#radioRC').prop('disabled', false);
    }
  }

  clickInput(input) {
    document.getElementById("input_" + input).click();
  }

  async comprobar_campos_paso1(evt = null) {
    if (evt) return false;

    let r = true;

    if (this.paso == 1) {

      for (let campo in this.formulario) {

        if (this.formulario[campo].paso == 1) {

          this.formulario[campo].error = null;

        }

      }

      if (this.formulario.centro.value == '' || this.formulario.centro.value == null) {
        this.formulario.centro.error = 'Debe seleccionar un centro';
        r = false;
      }
    }

    if (r) {
      await this.getGRM();
      await this.getTipologiaSiniestros();
    }

    return r;

  }


  async comprobar_campos_paso2(evt = null) {

    if (evt) return false;



    let r = true;

    if (this.paso == 2) {

       this.formulario.fecha.value =  $('#fecha').val() ;

      for (let campo in this.formulario) {

        if (campo == "poblacion") continue;

        if (this.formulario[campo].paso == 2) {

          this.formulario[campo].error = null;

          if (this.formulario[campo].value == "" || this.formulario[campo].value == null) {
            this.formulario[campo].error = "Rellena el campo";
            r = false;
          }
        }
      }

      let fecha = new Date(this.formulario.fecha.value);
      let hoy = new Date();
      let dias = Math.floor((fecha.getTime() - hoy.getTime()) / (1000 * 60 * 60 * 24));

      if (dias >= 0) {
        this.formulario.fecha.error = "La fecha no puede ser posterior a hoy";
        r = false;
      }

      let c = await this.checkCP(this.formulario.cp.value);

      if (!c) r = false;

    }
    return r;

  }

  async comprobar_campos_paso3(evt = null) {

    if (evt) return false;

    let r = true;

    if (this.paso == 3) {

      for (let campo in this.formulario) {

        if (this.formulario[campo].paso == 3) {

          if (this.formulario[campo].tipo_persona) {

            if (this.formulario[campo].tipo_persona != this.formulario.tipo_persona.value) continue;

          }

          if (this.formulario.tipoSiniestro.value == 'Daños') {

            if (!this.formulario[campo].danos) continue;

          }

          this.formulario[campo].error = null;


             if((this.formulario[campo].value == "" || this.formulario[campo].value == null)  && (campo!='dni_nif')) {
            this.formulario[campo].error = "Rellena el campo";
            r = false;
          }




        }

      }

      if (this.formulario.tipoSiniestro.value != 'Daños') {
        if (!(/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/).test(this.formulario.telefono.value)) {

          this.formulario.telefono.error = "Introduzca un número de teléfono válido";
          r = false;

        }

      }

      if (this.formulario.email.value != "") {
        if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.formulario.email.value))) {

          this.formulario.email.error = "Introduzca un email válido";
          r = false;

        }
      }



    }
    //
    // console.log('formulario',this.formulario);
    // console.log('return value r is', r);

    return r;

  }




  async comprobar_campos_paso4(evt = null) {

    if (evt) return false;

    let r = true;

    if (this.paso == 4) {
     // console.log('evaluando form contacto paso 4');

      for (let campo in this.formulario) {

        if (this.formulario[campo].paso == 4) {

          // if (campo == "email") continue;



          this.formulario[campo].error = null;

          if (campo != 'dni_nif_contacto_rc'  && this.formulario[campo].value == "") {
            this.formulario[campo].error = "Rellena el campo";
            r = false;
          }
        }
      }
      if (!(/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/).test(this.formulario.telefono_contacto_rc.value)) {

        this.formulario.telefono_contacto_rc.error = "Introduzca un número de teléfono válido";
        r = false;

      }

      if (this.formulario.email_contacto_rc.value != "") {
        if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.formulario.email_contacto_rc.value))) {

          this.formulario.email_contacto_rc.error = "Introduzca un email válido";
          r = false;

        }
      }

      if(this.formulario.dni_nif_contacto_rc.value =='' || this.formulario.dni_nif_contacto_rc.value ==null){
        r=true;
      }

    }



    return r;
  }

  /*
  async pideEmail(value) {

    this.confirmEmail = false;
    this.pide = true;

    if (value == 0) {

      this.pide = false;
      //await this.paso4()
      await this.paso5();

    } else {

      this.formulario.email.error = "Indique el email de contacto";

    }

  }
  */

  async paso2() {

    let c = await this.comprobar_campos_paso1();

    if (!c) return;

    this.paso = 2;

  }

  async paso3() {

    let c = await this.comprobar_campos_paso2();

    // console.log('paso 3 , c', c);

    if (!c) return;

    this.paso = 3;

  }

/*
  async paso4(){
    console.log('async paso4');
    let c = await this.comprobar_campos_paso3();
    console.log('paso 4 , c', c);

    if (!c) return;

    this.paso = 4;

  }
*/
/*
  async paso5() {



    if(this.formulario.tipoSiniestro.value!='Daños'){

     let c = await this.comprobar_campos_paso4();
     this.paso=4;
     console.log('comprobar_campos_paso4'  ,this.paso);

   }else{
    let c = await this.comprobar_campos_paso3();

    this.paso = 5;
    console.log('comprobar_campos_paso3'  ,this.paso);

  }
  if (!c) {

    return;

  }
  console.log('paso 5 , c', c);

}
*/

abrir_documento(file) {

  window.open(file.url);

}

aperturar() {
  this.init_formulario();
  this.apertura = true;
}

cancelarApertura() {
  this.apertura = false;
}

async checkCP(cp) {


 this.poblaciones = [];

 this.formulario.cp.error = null;

 if (this.formulario.cp.value == '') {

  this.formulario.cp.error = 'Rellena el campo';
  return false;

} else if (isNaN(parseFloat(this.formulario.cp.value))) {

  this.formulario.cp.error = 'Introduzca un código postal válido';
  return false;

} else if (this.formulario.cp.value.length != 5) {

  this.formulario.cp.error = 'Introduzca un código postal válido';
  return false;

}

this.cargando = true;
let result = await this.global.apiCall('hiperusera/checkCP', 'POST', false, {cp: cp});
this.cargando = false;

if (!result.status) {
  this.formulario.cp.error = result.message;
  return false;

}

this.poblaciones = result.data;
  this.formulario.poblacion.value = this.poblaciones[0]; //forzamos carga ciudad en desplegable


  return true;


}

existsFiles(name) {
  if (this.files.filter(f => f.document == name).length == 0) {
    return false;
  }
  return true;
}

async subir_documentos(fileInput: any, documentName, docId) {

  this.subiendo[docId] = true;

  let files = <Array<File>>fileInput.target.files;

  for (let file of files) {

    this.files.push({
      file: file,
      name: file.name,
      document: documentName,
      nombre: documentName + '_' + this.n_files[docId].toString(),
      id: docId
    })

    this.n_files[docId]++;

  }


  $('input[type="file"]').val('');

  this.subiendo[docId] = false;

}

async subir_ficha(fileInput: any) {

  this.subiendo_ficha = true;

  if (!this.n_files['ficha_abonado'] && !this.formulario['ficha_abonado']) {
    this.n_files['ficha_abonado'] = 1;

    this.formulario['ficha_abonado'] = {
      value: [],
      error: null,
      id: 'ficha_abonado',
      paso: 'documentos'
    }

  }

  let files = <Array<File>>fileInput.target.files;

  for (let file of files) {

    this.files.push({
      file: file,
      name: file.name,
      document: "ficha_abonado",
      nombre: "ficha_abonado" + '_' + this.n_files["ficha_abonado"].toString(),
      id: "ficha_abonado"
    })

  }

  this.error_abonado = null;

  this.subiendo_ficha = false;

  this.n_files['ficha_abonado']++;

}

uploadFile(file, name) {

  let size = file.size / 1024 / 1014;

  if (size >= 20) {
    return {
      status: false,
      message: "No se pueden subir archivos con un tamaño superior a 20Mb"
    };
  }

  var formData = new FormData();

  formData.append('select_file', file, file.name);
  formData.append('file_name', name);
  formData.append('sin_id', this.formulario.sin_id.value);

  return $.ajax({
    url: this.global.config.api + 'hiperusera/uploadFile',
    method: "POST",
    data: formData,
    dataType: 'JSON',
    contentType: false,
    cache: false,
    processData: false
  });

}

delete_file_doc(key, i) {

  this.files.splice(i, 1);

}

async uploadDocumentos() {

  if (this.documentos.find(d => d.doc_id == 'ficha_abonado') == null && this.formulario['ficha_abonado']) {
    this.documentos.push({ doc_id: 'ficha_abonado', doc_descripcion: 'ficha_abonado' });
  }

  for (let file of this.files) {
    this.formulario[file.document].value = [];
  }

  for (let file of this.files) {

    let result = await this.uploadFile(file.file, file.nombre);

    if (result.message) {
      alert("Se ha producido un error en la subida de documentos");
      return;
    }

    let url = result.url;
    let new_name = result.new_name;

    let type = file.file.type.split('/')[0];

    this.formulario[file.document].value.push({
      url: url,
      type: type,
      name: file.name,
      new_name: new_name
    });
  }
}


async grabar_siniestro() {

  let data = {};
  let result;

  if (this.formulario.sin_id.value == null || !this.formulario.sin_id.value) {

    this.cargando = true;

    for (let campo in this.formulario) {

      if (this.formulario[campo].paso != 'sin_id' && this.formulario[campo].paso != 'documentos') data[campo] = this.formulario[campo].value;

    }

    data['fecha']=this.convertDataYmd($('#fecha').val());
    // console.log('data send is ', data);
    result = await this.global.apiCall("hiperusera/grabar_siniestro", "POST", false, data);

    this.cargando = false;

    if (!result.status) {
      alert(result.message);
      return;
    }

    this.formulario.sin_id.value = result.data;

    const getAlias = await this.global.apiCall('siniestros/getSinAlias', 'POST', false, {sin_id: this.formulario.sin_id.value});
    this.sin_alias = getAlias.sin_alias;

  }

  this.cargando = true;

  await this.uploadDocumentos();

  data = {};

  for (let campo in this.formulario) {

    if (this.formulario[campo].paso != 'sin_id' && this.formulario[campo].paso != 'documentos') continue;

    data[campo] = this.formulario[campo].value;

  }

  result = await this.global.apiCall("hiperusera/cargarFicherosApertura", "POST", false, { data: data, docs: this.documentos });

  this.cargando = false;

  if (!result.status) {

    alert(result.message);
    return;

  } else {

    this.paso = 6;

  }

}

public explodeDireccion(direccion){
  var checkData = true;
  let explodeCentro=direccion.split(':');
  let identificador_centro=  explodeCentro[0];


  var cp =  explodeCentro[1].substring(
    explodeCentro[1].indexOf("(") + 1,
    explodeCentro[1].lastIndexOf(")")
    );

  if( cp.match(/^[0-9]+$/) == null || cp.length!=5)
  {
   checkData=false;
 }
 var indexSeparator = explodeCentro[1].lastIndexOf('-');
 let ciudad  = explodeCentro[1].substring(explodeCentro[1].lastIndexOf('-')+1,explodeCentro[1].indexOf("("));
 var direccion  =   explodeCentro[1].substring(0,explodeCentro[1].lastIndexOf('-'));



 return {'direccion':direccion,'cp':cp, 'status':checkData}


}

pasoback(paso){

  if(this.formulario.tipoSiniestro.value == 'Daños' && paso==5){
     var newpaso= paso-2;
     // console.log('newpaso',newpaso);
  }else{
     var newpaso= paso-1;

  }
   return this.paso = newpaso;
}


async evaluateNextStep(paso_actual){
  // console.log('this paso is ', paso_actual);
  // console.log('tipoSiniestro  is' , this.formulario.tipoSiniestro.value);


  if(paso_actual==3){
    // console.log('paso is 3');
    let c = await this.comprobar_campos_paso3();
    if (!c) {

      return;

    }
    // else{
    //   console.log('not fail evaluate comprobar_campos_paso3');
    // }
    if(this.formulario.tipoSiniestro.value=='Daños'){
      // console.log('go to 5');
      this.paso=5;
    }else{
     // console.log('go to 4');
     this.paso=4;
   }
 }
 if(paso_actual==4){
  // console.log('paso is 4');
  let c = await this.comprobar_campos_paso4();
  if (!c) {
    // console.log('!C paso 4');
    return;
  } else{
    // console.log('goto 5');
    this.paso=5;

  }

}
if(paso_actual!=3 && paso_actual!=4){
  // console.log('no entra en paso 3 ni paso = 4');
}
// console.log('paso ahora es', this.paso);

}

  convertDataYmd(date) {
    return date.split('/').reverse().join('/');
  }
}
